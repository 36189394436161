<template>
  <v-row justify="center">
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ action === 'Save' ? 'Add' : 'Update' }} Reseller</span>
        </v-card-title>
        <v-form
          ref="form"
          method="post"
          action="/"
          lazy-validation
          @submit.prevent="saveOrUpdate()"
        >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="8">
                  <v-text-field
                    v-model="form.name"
                    label="Name"
                    placeholder="Awesome Apartment"
                    hint="The name of the reseller"
                    :rules="[v => !!v || 'Name is required']"
                    required
                  >
                  </v-text-field>
                  <small
                    v-show="form.errors.has('name')"
                    class="validation-error"
                  >{{
                    form.errors.get('name')
                  }}</small>
                </v-col>
                <v-col
                  v-if="action === 'Save'"
                  cols="8"
                >
                  <v-text-field
                    v-model="form.user_name"
                    label="Default reseller name"
                    placeholder="John Doe"
                    hint="The name of the default reseller"
                    :rules="[v => !!v || 'Default reseller name is required']"
                    required
                  >
                  </v-text-field>
                  <small
                    v-show="form.errors.has('user_name')"
                    class="validation-error"
                  >{{
                    form.errors.get('user_name')
                  }}</small>
                </v-col>
                <v-col
                  v-if="action === 'Save'"
                  cols="8"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="form.email"
                    label="Email"
                    placeholder="john@example.com"
                    :rules="emailRules"
                    required
                  ></v-text-field>
                  <small
                    v-show="form.errors.has('email')"
                    class="validation-error"
                  >{{
                    form.errors.get('email')
                  }}</small>
                </v-col>
                <v-col
                  v-if="action === 'Save'"
                  cols="8"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="form.phone"
                    label="Phone number"
                    placeholder="07xxxxxxx"
                    :rules="optionalPhoneRules"
                    required
                  ></v-text-field>
                  <small
                    v-show="form.errors.has('phone')"
                    class="validation-error"
                  >{{
                    form.errors.get('phone')
                  }}</small>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="closeDialog()"
            >
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              type="submit"
              :loading="form.busy"
            >
              {{ action }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Form from 'vform'
import validationRules from '@/mixins/validationRules'

export default {
  mixins: [validationRules],
  props: {
    showDialog: {
      type: Boolean,
    },
    reseller: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    form: new Form({
      name: '',
      username: '',
      email: '',
      phone: '',
    }),
    isRolesLoading: false,
    action: 'Save',
  }),
  computed: {
    valueLabel() {
      return this.form.type === 1 ? 'Email address' : 'Phone number'
    },
    valueValidationRules() {
      return this.form.type === 1 ? this.emailRules : this.phoneRules
    },
  },
  watch: {
    reseller() {
      if (Object.keys(this.reseller).length > 0) {
        this.form.update(this.reseller)
        this.action = 'Update'
      } else {
        this.action = 'Save'
      }
    },
  },
  methods: {
    closeDialog() {
      this.$emit('close')
      this.$refs.form.reset()
    },
    saveOrUpdate() {
      const isFormValid = this.$refs.form.validate()
      if (isFormValid) {
        if (this.action === 'Save') {
          this.saveUser()
        } else {
          this.updateUser()
        }
      }
    },
    saveUser() {
      this.form
        .post('resellers')
        .then(() => {
          this.$notification.success('Reseller added successfully')
          const { email } = this.form
          setTimeout(() => {
            this.$notification.success(`An email has been sent to ${email} with instructions on how to setup their account.`)
          }, 1500)
          this.$emit('close')
          this.$emit('reseller-added')
          this.$refs.form.reset()
        })
        .catch(error => {
          this.$notification.error(error.response.data.message)
        })
    },
    updateUser() {
      this.form
        .put(`resellers/${this.form.id}`)
        .then(() => {
          this.$notification.success('Reseller updated successfully')
          this.$emit('close')
          this.$emit('reseller-added')
          this.$refs.form.reset()
        })
        .catch(error => {
          this.$notification.error(error.response.data.message)
        })
    },
  },
}
</script>
